const a = navigator.userAgent || navigator.vendor || window.opera;

export function breakLine(container) {
  const breakLine = container.querySelectorAll('p');
  for (let breakLineElement of breakLine) {
    let str = breakLineElement.textContent;
    let str_innerHtml = breakLineElement.innerHTML;
    let a7a = str_innerHtml.startsWith('<');
    if (str.trim() === '' && !a7a) {
      breakLineElement.classList.add('break-line');
    }
  }
}