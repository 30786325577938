import {
  allowPageScroll,
} from "../functions/prevent_allowPageScroll";

// region custom modal

// export function customModal(modalToggler, videoWrapper) {
//   const customModalEl = document.getElementById('custom-modal');
//   if (customModalEl) {
//     const modalContent = customModalEl.querySelector('.custom-modal-wrap');
//     // toggler function
//
//     modalToggler?.addEventListener('click', () => {
//       preventPageScroll();
//       customModalEl.classList.add('active');
//       modalContent.appendChild(videoWrapper);
//     })
//     videoWrapper.remove();
//     videoWrapper.removeAttribute('modal-content');
//
//
//     videoWrapper.remove();
//     videoWrapper.removeAttribute('modal-content');
//
//   }
// }
// endregion custom modal

export function initModal() {
  const customModalEl = document.querySelectorAll('.custom-modal');
  for (let customModalElElement of customModalEl) {
    if (customModalElElement) {
      const modalContent = customModalElElement.querySelector('.custom-modal-wrap');
      const modalContentHTML = modalContent.querySelector('.modal-content');
      const closeModal = () => {
        // allowPageScroll();
        // region allow page scroll
        document.documentElement.style.overflowY = 'auto';
        document.body.style.overflow = 'auto';
        // endregion allow page scroll
        customModalElElement.classList.remove('active');
      }

      const clearModal = () => {
        setTimeout(() => {
          modalContentHTML.innerHTML = '';
        }, 500)
      }

      const keyHandler = (e) => {
        if (e.key === 'Escape') {
          closeModal();
          // clearModal();
        }
      }
      window.addEventListener('keydown', keyHandler)

      customModalElElement.addEventListener('click', () => {
        closeModal();
        // clearModal();
      });
      customModalElElement.querySelector('.custom-modal-inner').addEventListener('click', function (e) {
        e.stopPropagation();
      });
      customModalElElement.querySelector('.close-modal').addEventListener('click', function (e) {
        closeModal();
        // clearModal();
      });
    }
  }

}
