import './index.html';
import './style.scss';
import {gsap} from 'gsap';
import {imageLazyLoading} from "../../scripts/functions/imageLazyLoading";
import {animations} from "../../scripts/general/animations";
import Swiper, {Pagination, Navigation, Autoplay, EffectFade} from "swiper";

Swiper.use([Pagination, Navigation, Autoplay, EffectFade]);

/**
 *
 * @param block {HTMLElement}
 * @returns {Promise<void>}
 */
const heroBlock = async (block) => {
  const heroContent = block.querySelector('.hero-content');
  const heroTimelineScale = heroContent.dataset.animationTimescale;
  const heroAutoPlayDuration = heroContent.dataset.autoplayDuration;
  const swiperContainer = block.querySelector('.swiper-container');
  const slides = swiperContainer.querySelectorAll('.swiper-container .swiper-slide');
  let swiper = new Swiper(swiperContainer, {
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    pagination: {
      el: block.querySelector('.swiper-pagination'),
      clickable: true,

    },
    autoplay: {
      delay: heroAutoPlayDuration ? heroAutoPlayDuration : 3000
    },
    slidesPerView: '1',
    spaceBetween: 25,
    allowTouchMove: false,
    observer: true,
    observeParents: true,

  });

  timelineAnimation(block.querySelector('.swiper-slide.swiper-slide-active'), true);

  function timelineAnimation(activeSlide, init) {
    const swiperImg = activeSlide.querySelector(".image-wrapper");
    const swiperTitle = activeSlide.querySelector(".title");
    const swiperDescription = activeSlide.querySelector(".description");
    const swiperBtn = activeSlide.querySelector(".btn");
    const swiperLink = activeSlide.querySelector(".link");
    const initState = init ? {delay: .5} : {};
    gsap.timeline(initState).timeScale(heroTimelineScale ? heroTimelineScale : 0.5)
      .fromTo(swiperImg, {
        opacity: 0,
        scale: 1.2
      }, {
        opacity: 1,
        scale: 1,
        ease: "power1"
      })
      .fromTo(swiperTitle, {
        opacity: 0,
        xPercent: 30
      }, {
        opacity: 1,
        xPercent: 0,
        ease: "power3"
      }, "<30%")
      .fromTo(swiperDescription, {
        opacity: 0,
        xPercent: 30
      }, {
        opacity: 1,
        xPercent: 0,
        ease: "power3"
      }, "<30%")
      .fromTo(swiperBtn, {
        opacity: 0,
        xPercent: 30
      }, {
        opacity: 1,
        xPercent: 0,
        ease: "power3"
      }, "<30%")
      .fromTo(swiperLink, {
        opacity: 0,
        xPercent: 30
      }, {
        opacity: 1,
        xPercent: 0,
        ease: "power3"
      }, "<20%");
  }

  swiper.on('realIndexChange', (swiper) => {
    const activeSlide = slides[swiper.activeIndex];
    timelineAnimation(activeSlide, false);
  })

  const logoShapes = block.querySelectorAll(".hero-logo path")
  gsap.timeline({
    delay: 0.3,
  })
    .from(logoShapes[0], {
      fill: "#FBBF49",
      opacity: 0,
      ease: "power3.inOut",
      xPercent: -50,
    })
    .from(logoShapes[1], {
      fill: "#FBBF49",
      opacity: 0,
      ease: "power3.inOut",
      xPercent: 30,
      yPercent: -50,
    }, "<20%")
    .from(logoShapes[2], {
      fill: "#FBBF49",
      opacity: 0,
      ease: "power3.inOut",
      xPercent: 50,
    }, "<20%")
    .from(logoShapes[3], {
      fill: "#FBBF49",
      opacity: 0,
      ease: "power3.inOut",
      xPercent: 20,
      yPercent: 50,
    }, "<20%");

  animations(block);
  imageLazyLoading(block);
};

export default heroBlock;

